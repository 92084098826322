import React from 'react';
import { Switch } from 'spoton-lib';

import { IPropTypes } from './ToggleableSection.types';
import styles from './ToggleableSection.module.scss';

export function ToggleableSection(props: IPropTypes): JSX.Element {
    const {
        isOpen,
        onToggle,
        sectionTitle,
        switchLabel,
        className,
        children,
    } = props;

    const isOpenClass = isOpen
        ? styles.ChildrenContainer___open
        : styles.ChildrenContainer___collapsed;
    return (
        <div className={`${styles.ToggleableSection} ${className}`}>
            <div className={styles.ToggleableSection_title}>{sectionTitle}</div>
            <div className={styles.ToggleableSection_switchContainer}>
                <div className={styles.SwitchContainer}>
                    <div className={styles.SwitchContainer_switch}>
                        <Switch checked={isOpen} onChange={onToggle} />
                    </div>
                    <div className={styles.SwitchContainer_label}>
                        {switchLabel}
                    </div>
                </div>
            </div>
            <div className={styles.ToggleableSection_content}>
                <div className={`${styles.ChildrenContainer} ${isOpenClass}`}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default ToggleableSection;
